import { observable, action } from 'mobx';
import { Auth, API } from 'aws-amplify';
import { BreakdownFromUserdata } from '../libs/RateTiers';

// DataType : Water, Sewer, Both
// Unit: kg, ccf
// VolumeSlider: 1-50
// Region: region1Avg, region2Avg, ... region10Avg
// WaterType: surfaceWaterAvg, groundWaterAvg, purchasedWaterAvg
// OwnershipType: privateAvg, specialDistrictAvg (municipalAvg)
// (Size)

export default function UIStore() {
  const emptyUserData = {utility: '', baseRateWater: '', baseRateSewer: '', unit: 'kg', 
    fields: [{water: null, sewer: null, tierStart: 0, tierEnd: 0 }]};
  // Settings here will be the defaults!
  const state = observable({
    showMyRates : true,
    showNational : true,
    showWaterType : false,
    waterType : 'surfaceWaterAvg', // or groundWaterAvg or purchasedWaterAvg
    showOwnerType : false,
    ownerType : 'privateAvg', // or municipalAvg or specialDistrictAvg
    showSize : false,
    size : 'pop1Avg', // pop1Avg .. pop4Avg or connect1Avg .. connect2Avg
    showRegion : false,
    region : 'northeastAvg', // region1Avg, region2Avg, ... region10Avg
    dataType : 'water', // or sewer or both
    unitType : 'kg', // or ccf
    volume : 30, // 5-50
    loggedIn : false,
    isAuthenticating : true,
    isLoading : true,
    newUser : {},
    userData : emptyUserData,
    rateBreakdown: {},
  });

  async function onLoad() {
    try {
      await Auth.currentSession(); // if there's no user, it will through exception with 'No current user' which is handled below
      setLoggedIn(true);
    }
    catch(e) {
      if (e !== 'No current user') {
        alert(e);
      }
    }

    setIsAuthenticating(false);

    if (getLoggedIn()) {
      await fetchUserData();
    }

    setIsLoading(false);
  }

  async function fetchUserData() {
    try {
      const body = await API.get('userdata', '/userdata');
      setUserData(body.content);
      const rb = BreakdownFromUserdata(body.content);
      setRateBreakdown(rb);
    } catch (e) {
      if (e.message !== 'Request failed with status code 404') {
        alert('error retrieving user data: ' + e);
      }
    }
  }

  function resetUserData() {
    setUserData(emptyUserData);
  }

  function getShowMyRates() {
    return state.showMyRates;
  }

  function getShowNational() {
    return state.showNational;
  }

  function getShowWaterType() {
    return state.showWaterType;
  }

  function getWaterType() {
    return state.waterType;
  }

  function getWaterTypeLabel() {
    switch (state.waterType) {
    case 'surfaceWaterAvg' :
      return 'Surface Avg';
    case 'groundWaterAvg' :
      return 'Groundwater Avg';
    case 'purchasedWaterAvg' :
      return 'Purchased Avg';
    default:
      throw new Error('Error: Unknown water type');
    }
  }

  function getShowOwnerType() {
    return state.showOwnerType;
  }

  function getOwnerType() {
    return state.ownerType;
  }

  function getOwnerTypeLabel() {
    switch (state.ownerType) {
    case 'municipalAvg' :
      return 'Municipal Avg';
    case 'privateAvg' :
      return 'Private Avg';
    case 'specialDistrictAvg' :
      return 'Special District Avg';
    default:
      throw new Error('Error: Unknown owner type');
    }
  }

  function getShowRegion() {
    return state.showRegion;
  }

  function getRegion() {
    return state.region;
  }

  function getRegionLabel() {
    // Handle 10 separately
    if (state.region === 'region10Avg') {
      return 'Region 10 Avg';
    }

    switch (state.region) {
    case 'northeastAvg' :
      return 'Northeast Avg';
    case 'midwestAvg' :
      return 'Midwest Avg';
    case 'southAvg' :
      return 'South Avg';
    case 'westAvg' :
      return 'West Avg';
    default:  
      // otherwise just get the only digit from regionXAvg
      return 'Region ' + state.region.charAt(6) + ' Avg';
    }
  }

  function getShowSize() {
    return state.showSize;
  }

  function getSize() {
    return state.size;
  }

  function getDataType() {
    return state.dataType;
  }

  function getUnitType() {
    return state.unitType;
  }

  function getVolume() {
    return state.volume;
  }

  function getLoggedIn() {
    return state.loggedIn;
  }

  function getIsAuthenticating() {
    return state.isAuthenticating;
  }

  function getIsLoading() {
    return state.isLoading;
  }

  function getNewUser() {
    return state.newUser;
  }

  function getUserData() {
    return state.userData;
  }    

  function getRateBreakdown() {
    return state.rateBreakdown;
  }

  // actions
  const setShowMyRates = action(function(val) {
    state.showMyRates = val;
  });

  const setShowNational = action(function(val) {
    state.showNational = val;
  });

  const setShowWaterType = action(function(val) {
    state.showWaterType = val;
  });

  const setWaterType = action(function(val) {
    state.waterType = val;
  });

  const setShowOwnerType = action(function(val) {
    state.showOwnerType = val;
  });

  const setOwnerType = action(function(val) {
    state.ownerType = val;
  });

  const setShowRegion = action(function(val) {
    state.showRegion = val;
  });

  const setRegion = action(function(val) {
    state.region = val;
  });

  const setShowSize = action(function(val) {
    state.showSize = val;
  });

  const setSize = action(function(val) {
    state.size = val;
  });

  const setDataType = action(function(val) {
    state.dataType = val;
  });

  const setUnitType = action(function(val) {
    state.unitType = val;
  });

  const setVolume = action(function(val) {
    state.volume = val;
  });

  const setLoggedIn = action(function(val) {
    state.loggedIn = val;
  });

  const setIsAuthenticating = action(function(val) {
    state.isAuthenticating = val;
  });

  const setIsLoading = action(function(val) {
    state.isLoading = val;
  });

  const setNewUser = action(function(val) {
    state.newUser = val;
  });

  const setUserData = action(function(val) {
    state.userData = val;
  });

  const setRateBreakdown = action(function(val) {
    state.rateBreakdown = val;
  });

  return Object.freeze( {
    onLoad,
    getShowMyRates,
    setShowMyRates,
    getShowNational,
    setShowNational,
    getShowWaterType,
    setShowWaterType,
    getWaterTypeLabel,
    getWaterType,
    setWaterType,
    getShowOwnerType,
    setShowOwnerType,
    getOwnerTypeLabel,
    getOwnerType,
    setOwnerType,
    getShowRegion,
    setShowRegion,
    getRegionLabel,
    getRegion,
    setRegion,
    getShowSize,
    setShowSize,
    getSize,
    setSize,
    getDataType,
    setDataType,
    getUnitType,
    setUnitType,
    getVolume,
    setVolume,
    getLoggedIn,
    setLoggedIn,
    getIsAuthenticating,
    setIsAuthenticating,
    getIsLoading,
    setIsLoading,
    getNewUser,
    setNewUser,
    getUserData,
    setUserData,
    getRateBreakdown,
    setRateBreakdown,
    fetchUserData,
    resetUserData,
  });
}