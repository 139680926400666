import { inject } from 'mobx-react';
import Region from '../components/Region';

const withPropsMapped = inject(function(stores){

  return {
    switchValue : stores.uiStore.getShowRegion(),
    onChangeSwitch : stores.uiStore.setShowRegion,
    regionValue : stores.uiStore.getRegion(),
    onChangeRegion : stores.uiStore.setRegion
  };
});

export default withPropsMapped(Region);