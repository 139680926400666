import React from 'react';
import { Row, Col, CustomInput  } from 'reactstrap';

const OwnerType = ({switchValue, onChangeSwitch, ownerValue, onChangeOwner}) => {

  return (
    <Row>
      <Col sm="7">
        <CustomInput
          id="show-owner-type"
          label="Show (national) average for type of ownership: "
          onChange={() => onChangeSwitch(!switchValue)}
          checked={switchValue}
          type="switch"
        />
      </Col>
      <Col sm="5">
        <CustomInput
          type="select"
          id="owner-type"
          name="owner-type"
          className="mb-3"
          value={ownerValue}
          onChange={({ target }) => onChangeOwner(target.value)}
          disabled={!switchValue}
        >
          <option value="municipalAvg">Municipal</option>
          <option value="privateAvg">Private</option>
          <option value="specialDistrictAvg">Special District</option>
        </CustomInput>
      </Col>
    </Row>
  );
};

export default OwnerType;
