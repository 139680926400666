import { inject } from 'mobx-react';
import Sample from '../components/Sample';

const withPropsMapped = inject(function(stores){

  /*    
    useEffect(() => {
        const interval = setInterval(stores.rateBreakdownStore.incrementRate, 1000);
        return () => { clearInterval(interval); }
    }, []);
*/
  
  const rb = stores.uiStore.getRateBreakdown();

  return {
    value : stores.uiStore.getShowMyRates(),
    onChange : stores.uiStore.setShowMyRates,
    utility : rb.controlLabel,
  };
});

export default withPropsMapped(Sample);