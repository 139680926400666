import React from 'react';
import { Card, CardBody, CardHeader, CustomInput  } from 'reactstrap';
import { Container, Row, Col } from 'reactstrap';

const DataType = ({value, onChange}) => {
  return (
    <Card>
      <CardHeader>Dataset</CardHeader>
      <CardBody>
        <Container>
          <p className="help">Some utilities provide drinking water, others provide sewer services, and many provide both. Select the prices you want to include.</p>
          <Row>
            <Col>
              <CustomInput
                name="datatype"
                id="datatype-water"
                label="Water"
                value="water"
                onChange={({ target }) => onChange(target.value)}
                checked={value === 'water'}
                type="radio"
              />
            </Col>
            <Col>
              <CustomInput
                name="datatype"
                id="datatype-sewer"
                label="Sewer"
                value="sewer"
                onChange={({ target }) => onChange(target.value)}
                checked={value === 'sewer'}
                type="radio"
              />
            </Col>
            <Col>
              <CustomInput
                name="datatype"
                id="datatype-both"
                label="Both"
                value="both"
                onChange={({ target }) => onChange(target.value)}
                checked={value === 'both'}
                type="radio"
              />
            </Col>
          </Row>  
        </Container>
      </CardBody>
    </Card>
  );
};

export default DataType;
