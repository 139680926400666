import { inject } from 'mobx-react';
import Size from '../components/Size';

const withPropsMapped = inject(function(stores){
  return {
    switchValue : stores.uiStore.getShowSize(),
    onChangeSwitch : stores.uiStore.setShowSize,
    sizeValue : stores.uiStore.getSize(),
    onChangeSize : stores.uiStore.setSize,
    options : stores.rateBreakdownStore.getSizeLabels()
  };
});

export default withPropsMapped(Size);