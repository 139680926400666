import { inject } from 'mobx-react';
import DataType from '../components/DataType';

const withPropsMapped = inject(function(stores){
  return {
    value : stores.uiStore.getDataType(),
    onChange : stores.uiStore.setDataType
  };
});

export default withPropsMapped(DataType);