import React from 'react';
import { Card, CardBody, CardHeader, CustomInput, Container, Row, Col  } from 'reactstrap';

const UnitType = ({value, onChange}) => {

  return (
    <Card>
      <CardHeader>Units</CardHeader>
      <CardBody>
        <Container>
          <p className="help">
            Most utilities measure water and sewer volumes in thousand gallon units or hundreds of cubic feet (CCF). Select the units that your utility uses.
          </p>
          <Row>
            <Col>
              <CustomInput
                name="unit"
                id="unit-kg"
                label="1,000 Gallons"
                value="kg"
                onChange={({ target }) => onChange(target.value)}
                checked={value === 'kg'}
                type="radio"
              />
            </Col>
            <Col>
              <CustomInput
                name="unit"
                id="unit-ccf"
                label="CCF"
                value="ccf"
                onChange={({ target }) => onChange(target.value)}
                checked={value === 'ccf'}
                type="radio"
              />
            </Col>
          </Row>
        </Container>
      </CardBody>
    </Card>
  );
};

export default UnitType;
