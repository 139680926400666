import React, { useState, useEffect } from 'react';
import { FormGroup, FormControl, Form } from 'react-bootstrap';
import { CustomInput } from 'reactstrap';
import './MyRates.css';
import LoaderButton from './LoaderButton';
import BreakdownFromTiers from '../libs/RateTiers';
import { NavLink } from 'react-router-dom';

function range(start, end) {
  let ans = [];
  for (let i = start; i <= end; i++) {
    ans.push(i);
  }
  return ans;
}

export default function MyRates({userData, myRateBreakdown, onSubmit}) {
  const [utility, setUtility] = useState(userData.utility);
  const [baseRateWater, setBaseRateWater] = useState(userData.baseRateWater);
  const [baseRateSewer, setBaseRateSewer] = useState(userData.baseRateSewer);
  const [isLoading, setIsLoading] = useState(false);
  const [unit, setUnit] = useState(userData.unit || 'kg');
  const topTier = '50+';
  const [fields, setFields] = useState(userData.fields ||
    [{ water: null, sewer: null, tierStart: 0, tierEnd: topTier }]);
  const bottomNum = 0;
  const topNum = 50;
  const [rateBreakdown, setRateBreakdown] = useState(myRateBreakdown);
  //const [billingCycle, setBillingCycle] = useState('monthly');

  useEffect( () => {
    const tiers = [...fields];
    const rb = BreakdownFromTiers(utility, baseRateWater, baseRateSewer, unit, tiers);
    setRateBreakdown(rb);
  }, [utility, baseRateWater, baseRateSewer, unit, fields]);

  function handleChange(i, event, waterOrSewer) {
    const values = [...fields];
    values[i][waterOrSewer] = event.target.value;
    setFields(values);
  }

  function handleAdd() {
    const values = [...fields];
    const lastTier = parseInt(values[values.length-1].tierStart);
    values[values.length-1].tierEnd = lastTier + 1;
    values.push({ water: null, tierStart: lastTier + 2, tierEnd: topTier });
    setFields(values);
  }

  function handleRemove(i) {
    const values = [...fields];
    values.splice(i, 1);
    if (i < values.length) {
      values[i-1].tierEnd = values[i].tierStart - 1;
    }
    else {
      values[i-1].tierEnd = topTier;
    }
    setFields(values);
  }

  function handleSelect(i, event) {
    const values = [...fields];
    values[i].tierStart = parseInt(event.target.value);
    values[i-1].tierEnd = values[i].tierStart-1;
    setFields(values);
  }

  function getTotal(i) {
    let water = parseFloat(fields[i].water);
    let sewer = parseFloat(fields[i].sewer);
    if (isNaN(water) && isNaN(sewer)) {
      return '';
    }
    water = isNaN(water) ? 0 : water;
    sewer = isNaN(sewer) ? 0 : sewer;
    const total = water + sewer;
    return total === 0 ? total : total.toFixed(2);
  }

  function getRate(unitNum, wsb, unitType) {
    if (typeof rateBreakdown === 'undefined' || typeof rateBreakdown[wsb] === 'undefined') {
      return 0;
    }
    else {
      return rateBreakdown[wsb][unitType][unitNum].toFixed(2);
    }
  }

  function validateForm() {
    return utility && utility.length > 0 && baseRateWater && baseRateWater.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    await onSubmit(utility, baseRateWater, baseRateSewer, unit, fields, rateBreakdown );
    setIsLoading(false);
  }

  function dropDown(start, idx) {
    const values = [...fields];
    const bottom = values[idx-1].tierStart+2;
    const top = values[idx].tierEnd === topTier ? 49 : parseInt(values[idx].tierEnd) - 1;
    return (
      <CustomInput
        key={`dropDown-${start}`}
        type="select"
        id={`dropDown-${start}`}
        name={`dropDown-${start}`}
        className="dd"
        value={start}
        onChange={e => handleSelect(idx, e)}
      >
        { range(bottom, top).map( (item) => {
          return (<option key={item} value={item}>{item}</option>);
        }) }
      </CustomInput>
    );
  }

  return (  
    <div className="MyRates">
      <form onSubmit={handleSubmit}>
        <h1>Edit My Utility Rates</h1>
        <FormGroup controlId="utility" size="lg" >
          <Form.Label>Utility Name</Form.Label>
          <FormControl
            autoFocus
            type="text"
            value={utility}
            onChange={e => setUtility(e.target.value)}
          />
        </FormGroup>
        <h3>Fixed Charges</h3>
        <p className='help'>The price customers must pay with each bill no matter how much water or sewer they use. Include all rates, fees, charges, and taxes that are not dependent on how much water or sewer they use.</p>
        <FormGroup controlId="baseRateWater" size="lg">
          <Form.Label>Water fixed charge</Form.Label>
          <FormControl
            value={baseRateWater}
            onChange={e => { setBaseRateWater(e.target.value); }}
            type="tel"
          />
        </FormGroup>
        <FormGroup controlId="baseRateSewer" size="lg">
          <Form.Label>Sewer fixed charge</Form.Label>
          <FormControl
            value={baseRateSewer}
            onChange={e => { setBaseRateSewer(e.target.value); }}
            type="tel"
          />
        </FormGroup>
        <h3>Volumetric Charges</h3>
        <p className="help">With volumetric pricing, customers pay some amount based on the volume of water or sewer they use each month. Some utilities have tiered pricing, which means the price per unit can change depending on the volume used. Note that your fixed charge might also include a volume allowance; for example, a fixed charge might cover the first 4,000 gallons. If so, then the volumetric price per unit of that first tier is zero (and the second tier would start at 5,000 gallons).</p>
        <FormGroup controlId="unit" size="lg">
          <Form.Label>Volumetric Unit Type</Form.Label>
          <CustomInput
            name="unit"
            id="unit-kg"
            label="1,000 Gallons"
            value="kg"
            onChange={({ target }) => { setUnit(target.value); } }
            checked={unit === 'kg'}
            type="radio"
          />
          <CustomInput
            name="unit"
            id="unit-ccf"
            label="CCF"
            value="ccf"
            onChange={({ target }) => { setUnit(target.value); } }
            checked={unit === 'ccf'}
            type="radio"
          />
        </FormGroup>
        <FormGroup>
          <button type="button" onClick={() => handleAdd()}>
        + Add Tier
          </button>
          <table>
            <thead>
              <tr><th className="cell">Unit Range</th><th>Water Rate</th><th>Sewer Rate</th><th>Total Rate</th></tr>
            </thead>
            <tbody>
              {fields.map((field, idx) => {
                return (
                  <tr key={`${field}-${idx}`}>
                    <td>
                      { idx === 0 ? '' :             
                        <button type="button" onClick={() => handleRemove(idx)}>
                          X
                        </button>
                      }
                      {idx === 0 ? '0' : dropDown(field.tierStart, idx)} to {field.tierEnd}</td>
                    <td><input
                      className='rate'
                      type="text"
                      placeholder="rate for tier"
                      value={field.water || ''}
                      onChange={e => handleChange(idx, e, 'water')}
                    />
                    </td>
                    <td><input
                      className='rate'
                      type="text"
                      placeholder="rate for tier"
                      value={field.sewer || ''}
                      onChange={e => handleChange(idx, e, 'sewer')}
                    />
                    </td>
                    <td>
                      { getTotal(idx) }
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </FormGroup>
        <LoaderButton
          block
          type="submit"
          size="lg"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Save Rate Info
        </LoaderButton>
        <p style={{textAlign: 'center', paddingTop: '15px'}}>
          <NavLink to="/">Return to Rate Comparison Graph</NavLink>
        </p>
      </form>
      <table style={{display: 'none'}}>
        <thead>
          <tr><th></th><th style={{textAlign: 'center'}} colSpan="3">1,000 Gallons</th><th style={{textAlign: 'center'}}  colSpan="3">CCF</th></tr>
          <tr><th># of units</th><th>water</th><th>sewer</th><th>both</th><th>water</th><th>sewer</th><th>both</th></tr>
        </thead>
        <tbody>
          {
            range(bottomNum, topNum).map( (item) => {
              return (
                <tr key={item}>
                  <td>{item}</td>
                  <td>{getRate(item, 'water', 'kg')}</td>
                  <td>{getRate(item, 'sewer', 'kg')}</td>
                  <td>{getRate(item, 'both', 'kg')}</td>
                  <td>{getRate(item, 'water', 'ccf')}</td>
                  <td>{getRate(item, 'sewer', 'ccf')}</td>
                  <td>{getRate(item, 'both', 'ccf')}</td>
                </tr>);
            }) 
          }
        </tbody>
      </table>
    </div>
  );
}