import React from 'react';
import { CustomInput, Col, Row  } from 'reactstrap';

const Sample = ({value, onChange, utility}) => {

  return (
    <Row>
      <Col sm="7">
        <CustomInput
          id="show-myrates"
          label={typeof utility === 'undefined' ? 'Show Sample Town' : 'Show ' + utility}
          onChange={() => onChange(!value)}
          checked={value}
          type="switch"
        />
      </Col>
      <Col>
      </Col>
    </Row>
  );
};

export default Sample;
