import { inject } from 'mobx-react';
import { Auth } from 'aws-amplify';
import Login from '../components/Login';

const withPropsMapped = inject(function(stores){

  return {
    onSubmit : async (email, password, history) => {
      try {
        await Auth.signIn(email, password);
        stores.uiStore.setLoggedIn(true);
        stores.uiStore.fetchUserData();
        history.push('/');
      } catch (e) {
        if (e.message === 'User is not confirmed.') {
          stores.uiStore.setNewUser({
            username: email,
            password: password,
          });
          history.push('/confirm');
        }
        else {
          alert(e.message);
        }
      }
    },
  };
});

export default withPropsMapped(Login);