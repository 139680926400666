export default {
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'https://87w7kd69ri.execute-api.us-east-1.amazonaws.com/dev',
  },
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_yhC8E86R9',
    APP_CLIENT_ID: '29gc99u0lg9b3u2ul1s587e5jg',
    IDENTITY_POOL_ID: 'us-east-1:25b80620-1c5b-4051-9ba4-3e9b253af074',
  },
};
