import React from 'react';
import { ResponsiveLine } from '@nivo/line';
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const MyResponsiveLine = ({ data /* see data tab */ , units}) => (
  <ResponsiveLine
    data={data}
    margin={{ top: 30, right: 50, bottom: 110, left: 60 }}
    xScale={{ type: 'point' }}
    yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
    yFormat={datum => datum.toFixed(2)}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      orient: 'bottom',
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: units,
      legendOffset: 36,
      legendPosition: 'middle'
    }}
    axisLeft={{
      orient: 'left',
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'Rate',
      legendOffset: -40,
      legendPosition: 'middle'
    }}
    colors={item => item.color}
    enablePoints={false}
    pointSize={10}
    pointColor={{ theme: 'background' }}
    pointBorderWidth={2}
    pointBorderColor={{ from: 'serieColor' }}
    pointLabel="y"
    pointLabelYOffset={-12}
    enableSlices="x"
    useMesh={true}
    sliceTooltip={({ slice }) => {
      return (
        <table
          style={{
            background: 'white',
            padding: '12px',
            border: '1px solid #ccc',
          }}
        >
          <tbody>
            {slice.points.map(point => (
              <tr
                key={point.id}
                style={{
                  color: point.serieColor,
                  padding: '3px',
                }}
              >
                <td style={{
                  padding: '3px 6px',
                }}>{point.serieId}</td>
                <td style={{
                  padding: '3px 6px',
                  textAlign: 'right',
                  fontFamily: 'source-code-pro, monaco, monospace',
                  fontWeight: 'bold'
                }}>{point.data.yFormatted}</td>
              </tr>
            ))}
          </tbody>
        </table>
      );
    }}
    legends={[
      {
        anchor: 'top-left',
        direction: 'column',
        justify: false,
        translateX: 20,
        translateY: 20,
        itemsSpacing: 0,
        itemDirection: 'left-to-right',
        itemBackground: 'white',
        itemWidth: 80,
        itemHeight: 20,
        itemOpacity: 0.85,
        symbolSize: 12,
        symbolShape: 'square',
        symbolBorderColor: 'rgba(0, 0, 0, .5)',
        effects: [
          {
            on: 'hover',
            style: {
              itemBackground: 'rgba(0, 0, 0, .03)',
              itemOpacity: 1,
              
              
            }
          }
        ]
      }
    ]}
  />
);

export default MyResponsiveLine;