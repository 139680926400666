import { inject } from 'mobx-react';
import Explain from '../components/Explain';

const withPropsMapped = inject(function(stores){

  return {
    isLoggedIn : stores.uiStore.getLoggedIn(),
  };

});

export default withPropsMapped(Explain);