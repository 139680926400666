import React from 'react';
import { Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

const AuthButtons = ({isAuthenticating, isLoggedIn, onLogout}) => {

  if (isAuthenticating) {
    return (<></>);
  }
  else if (isLoggedIn) {
    return (
      <LinkContainer to="/">
        <Nav.Link onClick={onLogout}>Logout</Nav.Link>
      </LinkContainer>
    );   
  }
  else {
    return (
      <>
        <LinkContainer to="/signup">
          <Nav.Link>Signup</Nav.Link>
        </LinkContainer>
        <LinkContainer to="/login">
          <Nav.Link>Login</Nav.Link>
        </LinkContainer>
      </>
    );
  }
};

export default AuthButtons;
