import { inject } from 'mobx-react';
import WaterType from '../components/WaterType';

const withPropsMapped = inject(function(stores){

  return {
    switchValue : stores.uiStore.getShowWaterType(),
    onChangeSwitch : stores.uiStore.setShowWaterType,
    waterValue : stores.uiStore.getWaterType(),
    onChangeWater : stores.uiStore.setWaterType
  };
    
});

export default withPropsMapped(WaterType);