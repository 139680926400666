import React from 'react';
import { Row, Col, CustomInput  } from 'reactstrap';

const WaterType = ({switchValue, onChangeSwitch, waterValue, onChangeWater}) => {
    
  return (
    <Row>
      <Col sm="7">
        <CustomInput
          id="show-water-type"
          label="Show (national) average for type of water supply:"
          onChange={() => onChangeSwitch(!switchValue)}
          checked={switchValue}
          type="switch"
        />
      </Col>
      <Col sm="5">
        <CustomInput
          type="select"
          id="water-type"
          name="water-type"
          className="mb-3"
          value={waterValue}
          onChange={({ target }) => onChangeWater(target.value)}
          disabled={!switchValue}
        >
          <option value="surfaceWaterAvg">Surface Water</option>
          <option value="groundWaterAvg">Ground Water</option>
          <option value="purchasedWaterAvg">Purchased</option>
        </CustomInput>
      </Col>
    </Row>
  );
};

export default WaterType;
