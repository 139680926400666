import React from 'react';
import { CustomInput, Col, Row  } from 'reactstrap';

const National = ({value, onChange}) => {

  return (
    <Row>
      <Col sm="7">
        <CustomInput
          id="show-national"
          label="Show national average"
          onChange={() => onChange(!value)}
          checked={value}
          type="switch"
        />
      </Col>
    </Row>
  );
};

export default National;
