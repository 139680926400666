import React from 'react';
import { CustomInput, Row, Col  } from 'reactstrap';

const Region = ({switchValue, onChangeSwitch, regionValue, onChangeRegion}) => {
  return (
    <Row>
      <Col sm="7">
        <CustomInput
          id="show-region"
          label="Show average for region:"
          onChange={() => onChangeSwitch(!switchValue)}
          checked={switchValue}
          type="switch"
        />
      </Col>
      <Col sm="5">
        <CustomInput
          type="select"
          id="region"
          name="region"
          className="mb-3"
          value={regionValue}
          onChange={({ target }) => onChangeRegion(target.value)}
          disabled={!switchValue}
        >
          <option value="northeastAvg">Northeast (Regions 1, 2 &amp; 3)</option>
          <option value="midwestAvg">Midwest (Regions 5 &amp; 7)</option>
          <option value="southAvg">South (Regions 4 &amp; 6)</option>
          <option value="westAvg">West (Regions 8, 9 &amp; 10)</option>
        </CustomInput>
      </Col>
    </Row>
  );
};

export default Region;
