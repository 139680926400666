export function BreakdownFromUserdata(ud) {
  return BreakdownFromTiers(ud.utility, ud.baseRateWater, ud.baseRateSewer, ud.unit, [...ud.fields]);
}

export default function BreakdownFromTiers(utility, baseRateWater, baseRateSewer, unit, tiers) {
  const bottomNum = 0;
  const topNum = 50;
  const rb = {
    legendLabel: utility,
    controlLabel: utility,
    water: {
      kg: [],
      ccf: [],
    },
    sewer: {
      kg: [],
      ccf: [],
    },
    both: {
      kg: [],
      ccf: [],
    },
    api: 1,
  };
  const brWater = parseFloat(baseRateWater) || 0;
  const brSewer = parseFloat(baseRateSewer) || 0;
  if (unit === 'kg') {
    rb.water.kg[bottomNum] = brWater;
    rb.water.ccf[bottomNum] = rb.water.kg[bottomNum] * 1.3368055556;
    rb.sewer.kg[bottomNum] = brSewer;
    rb.sewer.ccf[bottomNum] = rb.sewer.kg[bottomNum] * 1.3368055556;
    rb.both.kg[bottomNum] = brWater + brSewer;
    rb.both.ccf[bottomNum] = rb.water.ccf[bottomNum] + rb.sewer.ccf[bottomNum];
    if (tiers.length < 1) {
      throw new Error('Unexpected error: no tiers');
    }
    let tier = tiers.shift();
    for (let x = bottomNum + 1; x <= topNum; x++ ) {
      if (x > tier.tierEnd) {
        tier = tiers.shift();
      }
      rb.water.kg[x] = rb.water.kg[x-1] + parseFloat(tier.water);
      rb.water.ccf[x] = rb.water.kg[x] * 1.3368055556;
      rb.sewer.kg[x] = rb.sewer.kg[x-1] + parseFloat(tier.sewer);
      rb.sewer.ccf[x] = rb.sewer.kg[x] * 1.3368055556;
      rb.both.kg[x] = rb.water.kg[x] + rb.sewer.kg[x];
      rb.both.ccf[x] = rb.water.ccf[x] + rb.sewer.ccf[x];
    }  
  }
  else if (unit === 'ccf') {
    rb.water.ccf[bottomNum] = brWater;
    rb.water.kg[bottomNum] = rb.water.ccf[bottomNum] * 0.7480519480519;
    rb.sewer.ccf[bottomNum] = brSewer;
    rb.sewer.kg[bottomNum] = rb.sewer.ccf[bottomNum] * 0.7480519480519;
    rb.both.ccf[bottomNum] = brWater + brSewer;
    rb.both.kg[bottomNum] = rb.water.kg[bottomNum] + rb.sewer.kg[bottomNum];
    if (tiers.length < 1) {
      throw new Error('Unexpected error: no tiers');
    }
    let tier = tiers.shift();
    for (let x = bottomNum + 1; x <= topNum; x++ ) {
      if (x > tier.tierEnd) {
        tier = tiers.shift();
      }
      rb.water.ccf[x] = rb.water.ccf[x-1] + parseFloat(tier.water);
      rb.water.kg[x] = rb.water.ccf[x] * 0.7480519480519;
      rb.sewer.ccf[x] = rb.sewer.ccf[x-1] + parseFloat(tier.sewer);
      rb.sewer.kg[x] = rb.sewer.ccf[x] * 0.7480519480519;
      rb.both.ccf[x] = rb.water.ccf[x] + rb.sewer.ccf[x];
      rb.both.kg[x] = rb.water.kg[x] + rb.sewer.kg[x];
    }  
  }
  else {
    throw new Error ( 'Error: unknown unit type - ' + unit);
  }
  return rb;  
}