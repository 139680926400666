import React from 'react';
import MyRates from '../containers/MyRatesContainer';
// This is awful - I just need to prevent MyRates from loading the defaults. YUCK!
// I think I could get rid of this if I knew how to modify MyRatesContainer better
// OR figure out a way to make this useful - like with a spinner when it's loading
// but it would still be nice to make that reusable 

export default function MyRatesDelay({isLoading}) {

  return ( !isLoading && 
    <MyRates />
  );
}