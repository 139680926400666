import { inject } from 'mobx-react';
import National from '../components/National';

const withPropsMapped = inject(function(stores){

  return {
    value : stores.uiStore.getShowNational(),
    onChange : stores.uiStore.setShowNational
  };
});

export default withPropsMapped(National);