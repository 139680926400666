import React from 'react';
import { Container } from 'reactstrap';
import { NavLink } from 'react-router-dom';

const Explain = ( {isLoggedIn} ) => {
  return (
    <Container style={{textAlign: 'left'}}>
      <hr />
      <p>Rate Toolkit is a resource for assessing your utility&rsquo;s water and sewer prices in relation to other communities in the United States.</p>
      <p>What&rsquo;s special about the Rate Toolkit? We use a nationally representative dataset of single-family water &amp; sewer prices. Balanced picture; not skewed large. Unlike existing tools, this rate toolkit will allow you to analyze prices at different volumes.</p>
      { isLoggedIn ?
        <></>
        :
        <p>To get started, compare a sample utility to averages below or {' '}
          <NavLink to="/signup">sign up</NavLink> {' '}
        to enter your own data.</p>
      }
      <hr />
    </Container>
  );
};

export default Explain;
