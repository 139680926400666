//import { useEffect } from "react";
import { inject, observer } from 'mobx-react';
import Chart from '../components/Chart';

function chartPoint(value, index) {
  return {
    'x' : index.toString(),
    'y' : value
  };
}

const withPropsMapped = inject(function(stores){

  /*    
    useEffect(() => {
        const interval = setInterval(stores.rateBreakdownStore.incrementRate, 1000);
        return () => { clearInterval(interval); }
    }, []);
*/
  //    console.log("chart container")

  const dataType = stores.uiStore.getDataType();
  const unitType = stores.uiStore.getUnitType();
  const waterType = stores.uiStore.getWaterType();
  const ownerType = stores.uiStore.getOwnerType();
  const size = stores.uiStore.getSize();
  const region = stores.uiStore.getRegion();
  const vTop = stores.uiStore.getVolume()+1; // used for top range of slice, so +1
  //console.log("volume: " + volume);

  const data = [];

  if (stores.rateBreakdownStore.isDataLoaded()) {
    if (stores.uiStore.getShowRegion()) {
      data.push(
        {
          'id': stores.uiStore.getRegionLabel(),
          color: 'magenta',
          'data': stores.rateBreakdownStore.getRateBreakdown(region)[dataType][unitType].slice(0,vTop).map(chartPoint)
        }
      );
    }
    if (stores.uiStore.getShowSize()) {
      let rb = stores.rateBreakdownStore.getRateBreakdown(size);
      data.push(
        {
          'id': rb.legendLabel,
          'color': 'purple',
          'data': rb[dataType][unitType].slice(0,vTop).map(chartPoint)
        }
      );
    }
    if (stores.uiStore.getShowOwnerType()) {
      data.push(
        {
          'id': stores.uiStore.getOwnerTypeLabel(),
          'color': 'orange',
          'data': stores.rateBreakdownStore.getRateBreakdown(ownerType)[dataType][unitType].slice(0,vTop).map(chartPoint)
        }    
      );
    }
    if (stores.uiStore.getShowWaterType()) {
      data.push(
        {
          'id': stores.uiStore.getWaterTypeLabel(),
          'color': 'green',
          'data': stores.rateBreakdownStore.getRateBreakdown(waterType)[dataType][unitType].slice(0,vTop).map(chartPoint)
        }    
      );
    }
    if (stores.uiStore.getShowNational()) {
      data.push(
        {
          'id': 'National Avg',
          'color': 'red',
          'data': stores.rateBreakdownStore.getRateBreakdown('nationalAvg')[dataType][unitType].slice(0,vTop).map(chartPoint)
        }    
      );
    }
    if (stores.uiStore.getShowMyRates()) {
      const rb = stores.uiStore.getRateBreakdown();
      if (stores.uiStore.getLoggedIn() && typeof rb[dataType] !== 'undefined') {
        const rb = stores.uiStore.getRateBreakdown();
        data.push(            
          {
            'id': rb.legendLabel,
            'color': 'blue',
            'data': rb[dataType][unitType].slice(0,vTop).map(chartPoint)
          }
        );  
      }
      else {
        data.push(            
          {
            'id': 'Sample Town',
            'color': 'blue',
            'data': stores.rateBreakdownStore.getRateBreakdown('sampleInclining')[dataType][unitType].slice(0,vTop).map(chartPoint)
          }
        );  
      }
    }
  }

  return {
    data : data,
    units : unitType === 'ccf' ? 'Hundred Cubic Feet (CCF)' : '1,000 Gallons'
  };
});

export default withPropsMapped(observer(Chart));