import React from 'react';
import { Row, Col, CustomInput  } from 'reactstrap';

const Size = ({switchValue, onChangeSwitch, size, onChangeSize, options}) => {
  return (
    <Row>
      <Col sm="7">
        <CustomInput
          id="show-size"
          label="Show (national) average for size:"
          onChange={() => onChangeSwitch(!switchValue)}
          checked={switchValue}
          type="switch"
        />
      </Col>
      <Col sm="5">
        <CustomInput
          type="select"
          id="size"
          name="size"
          className="mb-3"
          value={size}
          onChange={({ target }) => onChangeSize(target.value)}
          disabled={!switchValue}
        >
          <Options options={options} />
        </CustomInput>
      </Col>
    </Row>
  );
};

const Options = ({options}) => {
  return options.map( opt => 
    <option key={opt[0]} value={opt[0]}>{opt[1]}</option>
  ); 
};

export default Size;
