import { inject } from 'mobx-react';
import { Auth } from 'aws-amplify';
import Confirm from '../components/Confirm';

const withPropsMapped = inject(function(stores){

  return {
    onSubmit : async (confirmation, history) => {
      const newUser = stores.uiStore.getNewUser();
      try {
        await Auth.confirmSignUp(newUser.username, confirmation);
        await Auth.signIn(newUser.username, newUser.password);
        stores.uiStore.setLoggedIn(true);
        stores.uiStore.setNewUser({});
        stores.uiStore.fetchUserData();
        history.push('/');
      } catch (e) {
        alert(e.message);
      }
    },
  };
});

export default withPropsMapped(Confirm);