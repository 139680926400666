function fetchRateBreakdowns() {
  return fetch('./rateBreakdowns.json')
    .then( response => {
      if (!response.ok) {
        throw new Error('HTTP error ' + response.status);
      } 
      return response; 
    })
    .then( result => result.json())
    .catch( (error) => { throw new Error('Fetch error ' + error); } );
  //return { message : "hey there" };
}

export default fetchRateBreakdowns;