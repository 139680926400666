import React from 'react';
import { Container, Card, CardBody, CardHeader  } from 'reactstrap';
import Slider from 'rc-slider';
// We can just import Slider or Range to reduce bundle size
// import Slider from 'rc-slider/lib/Slider';
// import Range from 'rc-slider/lib/Range';
import 'rc-slider/assets/index.css';

const VolumeSlider = ({value, onChange}) => {

  const marks = {
    5: '5',
    10: '10',
    15: '15',
    20: '20',
    25: '25',
    30: '30',
    35: '35',
    40: '40',
    45: '45',
    50: '50'
  };
  
  return (
    <Card>
      <CardHeader>Volume</CardHeader>
      <CardBody>
        <Container>
          <p className="help">The tool charts pricing up to 50,000 gallons or 50 CCF, but the vast majority of single-family residential customers use less than 30,000 gallons or 30 CCF per month. As a result, the graph isn’t very useful at the higher end of that range unless you’re interested in examining your very high-volume customers. A Volume Range of 30 is a good starting point, but you can adjust it up or down. </p>
        </Container>
        <Slider
          min={5}
          max={50}
          value={value}
          marks={marks}
          step={null}
          trackStyle={[{ backgroundColor: '#0A7BFF' }]}
          onChange={(value) => onChange(value)}
        />
      </CardBody>
    </Card>
  );
    
};

export default VolumeSlider;