import React from 'react';
import { Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

const MyRatesButtons = ({isLoggedIn}) => {
  return ( isLoggedIn &&
    <LinkContainer to="/myrates">
      <Nav.Link>Edit My Utility Rates</Nav.Link>
    </LinkContainer>
  );   
};

export default MyRatesButtons;
