import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from './Home';
import NotFound from './NotFound';
import Login from '../containers/LoginContainer';
import Signup from '../containers/SignupContainer';
import Confirm from '../containers/ConfirmContainer';
import MyRatesDelay from '../containers/MyRatesDelayContainer';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/login" exact component={Login} />
      <Route path="/signup" exact component={Signup} />
      <Route path="/confirm" exact component={Confirm} />
      <Route path="/myrates" exact component={MyRatesDelay} />
      { /* Finally, catch all unmatched routes */ }
      <Route component={NotFound} />       
    </Switch>
  );
}