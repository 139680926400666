import { observable, action } from 'mobx';
// In retrospect, this might not need to be an observable at all, since it doesn't really change
// In fact, consider if it would be better not to read the data from a file but simply to include it
// as a const, simply imported

export default function RateBreakdownStore(fetchRateBreakdowns) {
  const rateBreakdownMap = observable.map();

  fetchRateBreakdowns().then( 
    results => Object.keys(results).forEach( 
      name => changeRateBreakdown(name, results[name])
    )
  );

  function isDataLoaded() {
    return rateBreakdownMap.has('privateAvg');
  }

  function getRateBreakdown(name) {
    return rateBreakdownMap.get(name);
  }

  function getSizeLabels() {
    return GetLabels(/^(pop|connect)\d/);
  }

  // "private" utility functions
  function GetLabels(regex) {
    const options = [];
    for (const key of rateBreakdownMap.keys()) {
      if (regex.test(key)) {
        options.push([key, rateBreakdownMap.get(key).controlLabel]);
      }
    }
    return options;
  }
    
  // actions
  const changeRateBreakdown = action(function(name, rateBreakdown){
    rateBreakdownMap.set(name, rateBreakdown);
  });

  return Object.freeze( {
    isDataLoaded,
    getRateBreakdown,
    getSizeLabels,
    changeRateBreakdown
  });
}