import React from 'react';
import Chart from '../containers/ChartContainer';
import Settings from '../containers/SettingsContainer';
import Explain from '../containers/ExplainContainer';

function Home() {
  return (
    <>
      <Explain />
      <div className="ChartHolder" id='graph'>
        <p>MONTHLY SINGLE FAMILY RESIDENTIAL [WATER/SEWER/WATER&amp;SEWER] BILL BY VOLUME<br />
        About the data: Collected in Q4 of 2019</p>
        <Chart />
      </div>
      <Settings />
      <hr />
      <p>&copy; Copyright 2020 EJ Metrics</p>
      <p className="fine-print">icon courtesy <a href="https://fontawesome.com/license">font-awesome</a></p>
    </>
  );
}

export default Home;