import { inject } from 'mobx-react';
import Settings from '../components/Settings';

const withPropsMapped = inject(function(stores){

  return {
    isLoggedIn : stores.uiStore.getLoggedIn(),
  };

});

export default withPropsMapped(Settings);