import { inject } from 'mobx-react';
import MyRatesButton from '../components/MyRatesButton';

const withPropsMapped = inject(function(stores){

  return {
    isLoggedIn : stores.uiStore.getLoggedIn(),
  };

});

export default withPropsMapped(MyRatesButton);