import React, { useState } from 'react';
import { FormGroup, FormControl, Form, FormText } from 'react-bootstrap';
import './Confirm.css';
import LoaderButton from './LoaderButton';

export default function Confirm({onSubmit, history}) {
  const [confirmation, setConfirmation] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  function validateForm() {
    return confirmation.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    await onSubmit(confirmation, history);
    if (isLoading) { // if Login has unmounted, then setIsLoading chirps
      setIsLoading(false);
    }
  }
  
  return (
    <div className="Confirm">
      <form onSubmit={handleSubmit}>
        <FormGroup controlId="confirmation" bsSize="large">
          <Form.Label>Confirmation</Form.Label>
          <FormControl
            autoFocus
            type="tel"
            value={confirmation}
            onChange={e => setConfirmation(e.target.value)}
          />
        </FormGroup>
        <FormText>Please check your email for the code.</FormText>
        <LoaderButton
          block
          type="submit"
          bsSize="large"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Confirm
        </LoaderButton>
      </form>
    </div>
  );
}