import React from 'react';
import { Container, Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import DataType from '../containers/DataTypeContainer';
import UnitType from '../containers/UnitTypeContainer';
import National from '../containers/NationalContainer';
import Region from '../containers/RegionContainer';
import WaterType from '../containers/WaterTypeContainer';
import OwnerType from '../containers/OwnerTypeContainer';
import Size from '../containers/SizeContainer';
import Sample from '../containers/SampleContainer';
import VolumeSlider from '../containers/VolumeSliderContainer';
import { NavLink } from 'react-router-dom';

//import MyResponsiveLine from './Chart';


const Settings = ({isLoggedIn}) => {
  return (
    <Container>
      <Row>
        <Col>
          { isLoggedIn ? 
            <p style={{textAlign: 'left', zIndex: '100'}}>In this graph, the blue line depicts your utility rates and the red line shows the national average. Go to  <NavLink to="/myrates">Edit My Utility Rates</NavLink> to change your rate structure. Change other graph details below.</p>
            :
            <p style={{textAlign: 'left'}}>In this starter graph, the red line shows the national average, and the blue line depicts &ldquo;Sample Town,&rdquo; which can be replaced with your own data when you <NavLink to="/signup">sign up</NavLink>. Change graph details below.</p>
          }
        </Col>
      </Row>
      <Row>
        <Col sm="4">
          <DataType />
          <UnitType />
          <VolumeSlider />
        </Col>
        <Col sm="8">
          <Card>
            <CardHeader>Options</CardHeader>
            <CardBody className="left">
              <Container>
                <p className="help">Here you can select the types of utilities you’d like to compare with your own. </p>
                <Sample />
                { isLoggedIn ? 
                  <p className="help">Your utility rates. Go to <NavLink to="/myrates">Edit My Utility Rates</NavLink> to change your rate structure.</p> 
                  : 
                  <p className="help">You can <NavLink to="/signup">sign up</NavLink> to replace these prices with your own</p>
                }
                <National />
                <p className="help">A <strong>representative national sample</strong> of water and/or sewer utilities serving populations of at least 3,300. </p>
                <WaterType />
                <p className="help">Utilities draw their water supplies from surface water sources (rivers, lakes) or ground water (wells, aquifers), or they purchase wholesale supplies from another utility. These supply options can change utilities’ costs, which are reflected in the prices that customers pay. You may want to limit your graph to reflect only utilities that use the same kind water supply as yours.</p>
                <OwnerType />
                <p className="help">Most water and sewer systems in the US are owned and operated by municipal governments (cities, towns, villages, counties). Many are owned and operated by special district governments or authorities. A sizable minority of systems are investor-owned, for-profit companies. You can select which type of ownership you’d like to compare with your utility.</p>
                <Size />
                <p className ="help">You can select of a range of sizes of the utilities you want to include. Sizes are given by population served or number of service connections. </p>
                <Region />
                <p className="help">Averages for the four US Census regions. For convenience, the <NavLink to="https://www.epa.gov/aboutepa/visiting-regional-office">10 EPA regions</NavLink> are shown with their respective census regions.</p>
              </Container>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Settings;
