import { inject } from 'mobx-react';
import MyRates from '../components/MyRates';
import { API } from 'aws-amplify';

const withPropsMapped = inject(function(stores){
  return {
    userData : stores.uiStore.getUserData(),
    myRateBreakdown : stores.uiStore.getRateBreakdown(),
    onSubmit : async (utility, baseRateWater, baseRateSewer, unit, fields, rateBreakdown) => {
      console.log('during ' + JSON.stringify(rateBreakdown));
      stores.uiStore.setRateBreakdown(rateBreakdown);
      try {
        const userData = {
          utility : utility,
          baseRateWater : baseRateWater,
          baseRateSewer : baseRateSewer,
          unit : unit,
          fields : fields,
        };
        await API.put('userdata', '/userdata', {
          body : { content : userData },
        });
        //alert('success: ' + JSON.stringify(result));
        stores.uiStore.setUserData(userData);
      } catch (e) {
        alert('error: ' + e);
      }
    },
  };
});

export default withPropsMapped(MyRates);