import { inject } from 'mobx-react';
import { Auth } from 'aws-amplify';
import Signup from '../components/Signup';

const withPropsMapped = inject(function(stores){

  return {
    onSubmit : async (email, password, history) => {
      try {
        const newUser = {
          username: email,
          password: password,
        };
        await Auth.signUp(newUser);        
        stores.uiStore.setNewUser(newUser);
        history.push('/confirm');
      } catch (e) {
        alert(e.message);
      }
    },
  };
});

export default withPropsMapped(Signup);