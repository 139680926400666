import { inject } from 'mobx-react';
import AuthButtons from '../components/AuthButtons';
import { Auth } from 'aws-amplify';

const withPropsMapped = inject(function(stores){

  return {
    isAuthenticating : stores.uiStore.getIsAuthenticating(),
    isLoggedIn : stores.uiStore.getLoggedIn(),
    onLogout : async () => {
      await Auth.signOut();
      stores.uiStore.setLoggedIn(false);
      stores.uiStore.resetUserData();
      stores.uiStore.setRateBreakdown({});
      console.log('reset');
    },
  };

});

export default withPropsMapped(AuthButtons);