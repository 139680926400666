import { inject } from 'mobx-react';
import OwnerType from '../components/OwnerType';

const withPropsMapped = inject(function(stores){

  return {
    switchValue : stores.uiStore.getShowOwnerType(),
    onChangeSwitch : stores.uiStore.setShowOwnerType,
    ownerValue : stores.uiStore.getOwnerType(),
    onChangeOwner : stores.uiStore.setOwnerType
  };
});

export default withPropsMapped(OwnerType);