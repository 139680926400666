import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import fetchRateBreakdowns from './api/rateAPI.js';
import { Provider } from 'mobx-react';
import RateBreakdownStore from './stores/RateBreakdownStore';
import UIStore from './stores/UIStore';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router } from 'react-router-dom';
import Amplify from 'aws-amplify';
import config from './libs/config';

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  },
  API: {
    endpoints: [
      {
        name: 'userdata',
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
    ]
  }
});
//Amplify.Logger.LOG_LEVEL = 'DEBUG';
const rateBreakdownStore = RateBreakdownStore(fetchRateBreakdowns);
const uiStore = UIStore();
uiStore.onLoad();

ReactDOM.render(
  <Provider 
    rateBreakdownStore={rateBreakdownStore}
    uiStore={uiStore}
  >
    <React.StrictMode>
      <Router>
        <App /> 
      </Router>
    </React.StrictMode>
  </Provider>
  , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
