import { inject } from 'mobx-react';
import VolumeSlider from '../components/VolumeSlider';

const withPropsMapped = inject(function(stores){

  return {
    value : stores.uiStore.getVolume(),
    onChange : stores.uiStore.setVolume
  };
    
});

export default withPropsMapped(VolumeSlider);