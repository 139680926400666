/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import './App.css';
import { Navbar, Nav } from 'react-bootstrap';
import Routes from './components/Routes';
import { LinkContainer } from 'react-router-bootstrap';
import AuthButtons from './containers/AuthButtonsContainer';
import MyRatesButton from './containers/MyRatesButtonContainer';

function App() {
  return ( 
    <div className="App">
      <div className="App">
        <Navbar fluid="true" collapseOnSelect expand="sm">
          <LinkContainer to="/">
            <Navbar.Brand>Rate Toolkit</Navbar.Brand>
          </LinkContainer>            
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              {/*<Nav.Link href="/about">About Rate Toolkit</Nav.Link>*/}
              <MyRatesButton />
            </Nav>
            <Nav>
              <AuthButtons />
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Routes />
      </div>
    </div>
  );
}

export default App;
