import { inject } from 'mobx-react';
import UnitType from '../components/UnitType';

const withPropsMapped = inject(function(stores){

  return {
    value : stores.uiStore.getUnitType(),
    onChange : stores.uiStore.setUnitType
  };
  
});

export default withPropsMapped(UnitType);